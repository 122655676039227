import type { ToRefs } from 'vue'
import { leaseSectorOptions } from '~/constants/LeaseSector'
import {
  useApiPortfolioFilters,
  PropertyLeasePortfolioFilter,
  type IFilterValue,
  type ISearchPropertyLeasePortfolioRequest,
  type ILeaseReportTemplate,
} from '@portfolio'

export function useLeaseFilters(
  props: ToRefs<{
    modelValue: ISearchPropertyLeasePortfolioRequest
    template?: ILeaseReportTemplate | undefined
  }>,
) {
  const { modelValue: filters, template } = props
  const route = useRoute('division-companyId-portfolio')
  const companyId = route.params.companyId

  const { data: statuses, isFetching: isFetchingStatuses } =
    useApiPortfolioFilters(
      companyId,
      PropertyLeasePortfolioFilter.STATUS,
      // filters,
    )
  const { data: sectors, isFetching: isFetchingSectors } =
    useApiPortfolioFilters(
      companyId,
      PropertyLeasePortfolioFilter.SECTOR,
      // filters,
    )
  const { data: states, isFetching: isFetchingStates } = useApiPortfolioFilters(
    companyId,
    PropertyLeasePortfolioFilter.STATE,
    // filters,
  )

  const { allFields, isFetching: isFetchingFields } =
    useApiLeaseFieldGroupsByCompanyId(companyId)
  const fields = computed(() => {
    const allFieldsOptions = allFields.value.map((field) => ({
      label: field.name,
      value: camel(field.id),
    }))

    if (!template?.value || template.value.id === 'all') {
      return allFieldsOptions
    }

    return allFieldsOptions.filter(({ value }) =>
      (template?.value?.template?.fields ?? [])
        .map((v) => camel(v))
        .includes(value),
    )
  })

  return {
    statuses: computed(() =>
      Object.keys(statuses.value?.items ?? {}).map((option) => ({
        label: title(option),
        value: camel(option),
      })),
    ),
    sectors: leaseSectorOptions,
    states: computed(() => parseOptions(states.value?.items ?? {})),
    fields,
    isFetchingStatuses,
    isFetchingSectors,
    isFetchingStates,
    isFetchingFields,
  }
}

function pascalToCamel(pascal: string) {
  return pascal.charAt(0).toLowerCase() + pascal.slice(1)
}

function parseOptions(
  options: Record<string, IFilterValue>,
  labelMap?: Record<string, string>,
) {
  return Object.keys(options).map((option) => {
    let label = option
    let value = option

    if (option === '[none]') {
      label = 'None'
      value = 'null'
    }

    return {
      label: labelMap ? labelMap[pascalToCamel(label)] : label,
      value,
    }
  })
}
