/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum PropertyLeasePortfolioFilter {
    STATUS = 'status',
    LESSEE = 'lessee',
    LESSOR = 'lessor',
    SECTOR = 'sector',
    COUNTRY = 'country',
    STATE = 'state',
    CITY = 'city',
    TRADING_NAME = 'tradingName',
}
