import { useQuery, keepPreviousData } from '@tanstack/vue-query'
import type {
  PropertyLeasePortfolioFilter,
  ISearchPropertyLeasePortfolioRequest,
  IPropertyLeasePortfolioFilterValueResponse,
} from '@portfolio'
import { GroupByOption, SortColumn, SortDirection } from '@portfolio'

export const useApiPortfolioFilters = (
  companyId: string,
  filter: PropertyLeasePortfolioFilter,
  // filters: Ref<ISearchPropertyLeasePortfolioRequest>,
) => {
  const filters = ref<ISearchPropertyLeasePortfolioRequest>({
    groupBy: GroupByOption.NONE,
    sort: SortColumn.NAME,
    direction: SortDirection.ASCENDING,
  })
  const _parsedFilters = computed(() => parseFilters(filters.value))
  return useQuery({
    queryKey: ['useApiPortfolio', companyId, filter, _parsedFilters],
    queryFn: () =>
      fetchAPI<IPropertyLeasePortfolioFilterValueResponse>(
        `/companies/${companyId}/portfolio/property/filters/${filter}`,
        {
          method: 'POST',
          body: _parsedFilters.value,
        },
      ),
    placeholderData: keepPreviousData,
  })
}
